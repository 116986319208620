import StoreIsdc from "../types/StoreIsdc"

/**
 * @param  {number} country - the country of the store
 * @returns {Date} the earliest acceptable date
 */
function getMaxDateByCountry(country: StoreIsdc['country']): Date {
	const date = new Date()
	const age = 18 //legal age is now set to 18 for US as well. Before April 2025, it was:  country === 'US' ? 16 : 18
	const year = date.getFullYear() - age
	date.setFullYear(year)
	return date
}

export { getMaxDateByCountry }
